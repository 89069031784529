.App-header {
  background-color: #f6f6f6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: url(images/aerial.jpg);
  background-size: 2600px;
  background-repeat: no-repeat;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

h1 {
  text-align: center;
}

.Header-container {
  display: flex;
  flex-direction: column;
  align-items: right;
  padding: 16px 10px 32px 10px;
}

.Header {
  position: fixed;
  top: 0;
  text-align: center;
}

.Header-image {
  height: 300px;
}

.Socials {
  margin: 0 0.5rem !important;
}

.Socials:hover {
  color: #f082b0;
}

.Ticker-container {
  display: flex;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.postNominals {
  color: #f0da82;
}

.description {
  color: white;
}

.Projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 5px;
}

.Project {
  min-width: 500px;
  padding: 10px 32px;
  align-items: center;
  text-align: center;
}

.Songer-heading {
  max-width: 430px;
}

.WithoutAHitch-heading {
  font-family: PinyonScript;
  font-size: 119px;
  color: lightpink;
  padding: 0 32px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 32px;
  font-weight: 300;
}

.Name-heading {
  font-size: 12px;
}

.Songer-link {
  min-width: 500px;
}

@media screen and (max-width: 659px) {
  .Header-container {
    align-items: center;
  }
}

@media screen and (max-width: 510px) {
  .WithoutAHitch-heading {
    font-size: 60px;
  }

  .Songer-heading {
    max-width: 320px;
  }
}

@media screen and (max-width: 320px) {
  .WithoutAHitch-heading {
    font-size: 40px;
  }

  .Songer-heading {
    max-width: 250px;
  }

  .Name-heading {
    font-size: 12px;
  }
}

.AWSLogo {
  height: 100px;
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
